import React from "react";

export default ({ width = '24px', height = '24px' }) => (
  <svg
    className="zoom-in"
    xmlns="http://www.w3.org/2000/svg" 
    width={ width }
    height={ height }
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);
